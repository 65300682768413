<template>
  <div>
    <div>
      <h1 class="heading">Order statistics</h1>
      <ObjectFilter
        :object_id="$store.state.statistics.object_id"
        :objects="objects.list"
        @changeObject="changeObject"
        @changeDates="changeDates"
        @reloadData="reloadData"
      />
    </div>
    <div class="statistics">
      <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
      <v-autocomplete
          :style="`width:${isMobile?'150px':'320px'};`"
          class="statistics__type"
          solo
          flat
          dense
          outlined
          :placeholder="$t('statistics[\'Открытия\']')"
          :items="
              Object.keys(orders).map((key) => ({ status: key, name: key }))
            "
          item-value="page"
          item-text="name"
          v-model="current_status"
          @change="changeStatus"
      >
        <template v-slot:selection="{ item }">
          {{ statuses[item.status] }}
        </template>
        <template v-slot:item="{ item }">
          {{ statuses[item.status] }}
        </template>
      </v-autocomplete>
      <div class="statistics__chart">
        <Chart
                  style="height:415px"
                  :height="415"
            :data="chart_data"
            :labels="dateArr"
            :pointRadius="4"
            :xAxes_ticks="true"
        />
      </div>
    </div>
      <div class="statistics__info" v-if="!isMobile">
        <h3 class="statictics__caption">
          Count of orders
        </h3>
        <p class="statistics__period">
          <span>{{ all_visitors }}</span>
          for selected period
        </p>
        <p class="statistics__total">
              <span
              >The graph shows the number of orders from your mobile
                application: {{ hotelName }}</span
              >
        </p>
      </div>
    </div>
    <v-btn color="primary" outlined small @click="downloadStatistics">
      Download csv
    </v-btn>
  </div>
</template>

<script>
import StatisticsService from "@/services/statistics.service";
import { mapState } from "vuex";
import store from "@/store";
import titleMixin from "@/mixins/titleMixin";
import HotelService from "@/services/hotel.service";
export default {
  name: "OrderStatistics",
  mixins: [titleMixin],
  title() {
    return "Order Statistics";
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.objects.list.length) {
      store
        .dispatch("objects/getList")
        .then((data) => {
          next();
        })
        .catch(() => {
          next();
        });
    } else {
      next();
    }
  },
  data() {
    return {
      hotelName: "",
      current_status: "All",
      statistics: [],
      chart_data: [],
      dateArr: [],
      all_visitors: 0,
      total_visitors: 0,
      orders: {},
      headers: [
        { text: "Date", value: "day" },
        { text: "New", value: "new" },
        { text: "In process", value: "is_processing" },
        { text: "Completed", value: "completed" },
        { text: "Canceled", value: "canceled" },
        { text: "Deleted", value: "deleted" },
      ],
      tableItems: [],
      statuses: {
        All: "All",
        canceled: "Canceled",
        completed: "Completed",
        is_processing: "In process",
        deleted: "Deleted",
        new: "New",
      },
      isMobile:false,
    };
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  computed: {
    ...mapState(["objects"]),
  },
  components: {
    Chart: () => import("@/components/Chart.vue"),
    ObjectFilter: () => import("@/components/ObjectFilter.vue"),
  },
  async mounted() {
  },
  methods: {
    async downloadStatistics() {
      try {
        const payload = {
          hotel_id: this.$store.state.statistics.object_id,
          date_start: this.$store.state.statistics.date_start,
          date_end: this.$store.state.statistics.date_end,
        };
        const data = await HotelService.downloadStatistics(payload);
        const dataArr = data.split("/");
        const name = dataArr[dataArr.length - 1];
        const link = document.createElement("a");
        link.download = name;
        link.href = data;
        const evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        link.dispatchEvent(evt);
        link.remove();
      } catch (e) {
        return e;
      }
    },
    async reloadData() {
      await this.getOrderStatistics();
    },
    formatDateForTable(date) {
      if (date.includes("-")) {
        const dateArr = date.split("-");
        return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
      } else {
        return date;
      }
    },
    formatDateForStatistics(date) {
      const dateArr = date.split(".");
      return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
    },
    formatDateForChart(date) {
      if (date.includes("-")) {
        const dateArr = date.split("-");
        return `${dateArr[2]}.${dateArr[1]}`;
      } else {
        return date;
      }
    },
    changeStatus() {
      this.chart_data = this.orders[this.current_status];
      this.total_visitors = this.orders[this.current_status].reduce(
        (acc, item) => {
          acc += item;
          return acc;
        },
        0
      );
      this.all_visitors = this.total_visitors;
    },
    async getOrderStatistics() {
      this.hotelName = this.objects.list.find((x) => x.id === this.$store.state.statistics.object_id).name;
      const payload = {
        hotel_id: this.$store.state.statistics.object_id,
        date_start: this.formatDateForStatistics(
          this.$store.state.statistics.date_start
        ),
        date_end: this.formatDateForStatistics(
          this.$store.state.statistics.date_end
        ),
      };
      if (payload.date_end === payload.date_start) {
        this.statistics = await StatisticsService.getOrderHoursStatistics(
          payload
        );
      } else {
        this.statistics = await StatisticsService.getOrderStatistics(payload);
      }
      this.orders = { All: [] };
      this.dateArr = [];
      this.tableItems = [];
      for (let i in this.statistics) {
        if (this.statistics[i]) {
          for (let key in this.statistics[i]) {
            if (!this.orders[key]) {
              this.orders[key] = [];
            }
          }
        }
      }
      let ind = 0;
      for (let i in this.statistics) {
        this.dateArr.push(this.formatDateForChart(i));
        if (this.statistics[i]) {
          const dataTable = {
            day: this.formatDateForTable(i),
            new: 0,
            is_processing: 0,
            completed: 0,
            canceled: 0,
            deleted: 0,
          };

          this.orders.All.push(0);
          for (let key in this.statistics[i]) {
            this.orders[key].push(this.statistics[i][key]);
            dataTable[key] = this.statistics[i][key];
            this.orders.All[ind] += this.statistics[i][key];
          }
          this.tableItems.push(dataTable);
        } else {
          const dataTable = {
            day: this.formatDateForTable(i),
            new: 0,
            is_processing: 0,
            completed: 0,
            canceled: 0,
            deleted: 0,
          };
          this.tableItems.push(dataTable);
          for (let key in this.orders) {
            this.orders[key].push(0);
          }
        }
        ind++;
      }
      this.chart_data = this.orders.All;
      this.total_visitors = this.orders.All.reduce((acc, item) => {
        acc += item;
        return acc;
      }, 0);
      this.all_visitors = this.total_visitors;
    },
    changeObject(id) {
      this.$store.state.statistics.object_id = id;
      if (
        !this.$store.state.statistics.date_start &&
        !this.$store.state.statistics.date_end
      ) {
        let end = this.$moment().format("YYYY-MM-DD");
        let start = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
        this.$store.state.statistics.date_start =
          this.$moment(start).format("DD.MM.YYYY");
        this.$store.state.statistics.date_end =
          this.$moment(end).format("DD.MM.YYYY");
      }
      this.hotelName = this.objects.list.find((x) => x.id === id).name;
      this.getOrderStatistics();
    },
    changeDates(dates) {
      this.labels = [];
      if (dates.date_start && dates.date_end) {
        this.$store.state.statistics.date_start = this.$moment
          .unix(dates.date_start)
          .format("DD.MM.YYYY");
        this.$store.state.statistics.date_end = this.$moment
          .unix(dates.date_end)
          .format("DD.MM.YYYY");
      } else {
        this.$store.state.statistics.date_start = dates.date_start;
        this.$store.state.statistics.date_end = dates.date_end;
      }
      this.getOrderStatistics();
    },
  },
};
</script>

<style lang="scss">
.statistics {
  @include box;
  display: flex;
  // align-items: flex-end;
  margin-bottom: 30px;

  canvas {
    width: 100% !important;
    height: 300px !important;
  }
}

.statistics__statistics {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(66.66% + 20px);
}

.statistics__type {
  position: absolute;
  width: 320px;
  top: 30px;
  left: 60px;
}

.statistics__chart {
  margin-top: auto;
}

.statistics__info {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 20px);
}

.statictics__caption {
  font-size: 16px;
  font-weight: 600;
  padding: 40px 40px 0;
}

.statistics__period {
  margin-bottom: auto;
  padding: 40px;
  font-weight: 500;
  font-size: 14px;
  color: #7f8385;
  span {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 80px;
    line-height: 1.21;
    color: #007db5;
    white-space: nowrap;
  }
}

.statistics__total {
  margin-top: auto;
  padding: 40px;
  border-radius: 0 0 5px 0;
  background: rgb(190, 193, 194);
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  span {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.22;
  }
}

.statistics__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.statistics canvas{
  height: 415px !important;
}
</style>
