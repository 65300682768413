import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('h1',{staticClass:"heading"},[_vm._v("Order statistics")]),_c('ObjectFilter',{attrs:{"object_id":_vm.$store.state.statistics.object_id,"objects":_vm.objects.list},on:{"changeObject":_vm.changeObject,"changeDates":_vm.changeDates,"reloadData":_vm.reloadData}})],1),_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":Object.keys(_vm.orders).map(function (key) { return ({ status: key, name: key }); }),"item-value":"page","item-text":"name"},on:{"change":_vm.changeStatus},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statuses[item.status])+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statuses[item.status])+" ")]}}]),model:{value:(_vm.current_status),callback:function ($$v) {_vm.current_status=$$v},expression:"current_status"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{staticStyle:{"height":"415px"},attrs:{"height":415,"data":_vm.chart_data,"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" Count of orders ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.all_visitors))]),_vm._v(" for selected period ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows the number of orders from your mobile application: "+_vm._s(_vm.hotelName))])])]):_vm._e()]),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.downloadStatistics}},[_vm._v(" Download csv ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }